import './App.css';
import { io } from 'socket.io-client';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { privateRoutes } from './routes';
import Overlay from './components/Overlay';
import Loading from './components/Loading';
import DefaultLayout from './layouts/DefaultLayout';
import ToastMessage from './components/ToastMessage';
import { setSocketPlayer } from './redux/reducer/player';
import { setSocketCharging } from './redux/reducer/stats';

function App() {
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState(null);

    const { currentUser } = useSelector((state) => state.auth);
    const { sidebar, loading } = useSelector((state) => state.module);

    const dispatch = useDispatch();

    // Khởi tạo socket
    useEffect(() => {
        const newSocket = io('https://napthex5.vn');

        // Kết nối thành công
        newSocket.on('connect', () => {
            if (currentUser?.status) {
                setMessage({ type: 'success', message: 'Kết nối socket.io thành công' });
            }
        });
        // Kết nối thất bại
        newSocket.on('connect_error', () => {
            if (currentUser?.status) {
                setMessage({ type: 'error', message: 'Kết nối socket.io thất bại' });
            }
        });
        // Kết nối quá thời gian
        newSocket.on('connect_timeout', () => {
            if (currentUser?.status) {
                setMessage({ type: 'error', message: 'Kết nối socket.io quá thời gian' });
            }
        });

        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Nhận thẻ mới
    useEffect(() => {
        if (!socket) return;
        socket.on('chargings', (response) => {
            if (!response) return;

            dispatch(setSocketCharging(response));
            let type = 'warning';
            if (response.status === 1) {
                type = 'success';
            }
            if (response.status === 2) {
                type = 'info';
            }
            if (response.status === 3) {
                type = 'error';
            }
            setMessage({ type, message: `Thẻ nạp mới mã: ${response.code}` });
        });

        return () => {
            socket.off('chargings');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    // Nhận người dùng mới
    useEffect(() => {
        if (!socket) return;
        socket.on('player_login', (response) => {
            if (!response) return;

            dispatch(setSocketPlayer(response));
            setMessage({ type: 'success', message: `Người dùng mới Nickname: ${response.nickname}` });
        });

        return () => {
            socket.off('player_login');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <div className="App">
            {sidebar && <Overlay />}
            {loading && <Loading />}
            {message && <ToastMessage app={message} />}

            <Router>
                <Routes>
                    {privateRoutes.map((route, index) => {
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                </Routes>
            </Router>
        </div>
    );
}

export default App;
